import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./ShepardFairey.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import ShepardFaireyPhoto1 from "../../../../res/Photos site/Shepard Fairey/Shepard Fairey- Big Brother Studio.png"
import ShepardFaireyPhoto2 from "../../../../res/Photos site/Shepard Fairey/shepard-fairey.jpeg"
import ShepardFaireyPhoto3 from "../../../../res/Photos site/Shepard Fairey/shepard-fairey by Shepard Fairey.webp"
import ShepardFaireyPhoto4 from "../../../../res/Photos site/Shepard Fairey/Shepard Fairey by Brent Broza.jpg"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/fairey/portrait.jpg'
import PastPresent1 from "../../../../res/fairey/past-present-1.jpg"
import PastPresent2 from "../../../../res/fairey/past-present-2.png"
import PastPresent3 from "../../../../res/fairey/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "ShepardFairey",
  name: "ShepardFairey",
  description:
  "Born in 1970, Shepard Fairey lives and works in Los Angeles. He graduated from the Rhode Island School of Design in 1992. He became known thanks to a campaign of stickers and posters posted throughout Providence while he was still a student. They represent the French wrestler Andre Roussimoff, with the slogan \"Andre the giant has a posse\". During the 1990's, he extended this poster campaign around the world and signed Obey Giant. His work then focuses on the reproduction of his images and their dissemination in urban space. He uses visual codes borrowed from political propaganda or advertising to develop a graphic style, with stylized lines and a reduced color palette. He hijacks photographs or old posters, which he appropriates through his graphic treatment and whose meaning he changes. In the 2000's he made many portraits of icons, which he sometimes used to support various social or political causes. In 2008 he supports Barack Obama during the American presidential campaign by creating a poster of him with the slogan \"HOPE\". This portrait is now on display at the Smithsonian National Portrait Gallery in Washington DC. For the past 25 years, Shepard has mobilized for ecology and against the consequences of global warming. Concerned for future generations, he hopes that the message throughout his works will help raise public awareness of the crisis facing our planet. Shepard Fairey has created more than 60 murals around the world, including in Paris with the mural \"Rise Above Rebel\" as part of the Street Art 13 project. His work is exhibited in several international museums, such as the Museum of Modern Art in San Francisco, the Los Angeles County Museum of Art, the Institute of Contemporary Art in Boston and the Victoria & Albert Museum in London.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de ShepardFairey.",
  photos: [
    { src: PastPresent1, name: "ShepardFairey" },
    { src: PastPresent2, name: "ShepardFairey" },
    { src: PastPresent3, name: "ShepardFairey" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

const ShepardFairey = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
     <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>SHEPARD FAIREY <h2 style={{paddingLeft: "16px"}}>(French)</h2></h1>
          <h3 className={NameWrapper} >Born in 1970</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
        <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Online: shepard fairey: 3 Decades of dissent, galleria comunale d`arte moderna roma rome, italy, dec 16,2020 - mar 13,2022</p>
          <p>- Shepard Fairey: Backward Forward ,Dallas Contemporary ,Dallas, Texas, USA</p>
          <p>- Shepard Fairey: Facing The Giant ,Amarillo Museum of Art ,Amarillo, Texas, USA</p>
          <p>- Shepard Fairey: Inspiring | Controversial | Obey! ,Richard F. Brush Art Gallery ,Canton, New York, USA</p>
          <p>- Shepard Fairey: Eyes Open - Minds Open ,LOTTE Museum of Art ,Songpa-gu, Seoul, South Korea</p>
          <p>- Shepard Fairey : Good Trouble ,Mayberry Fine Art, Toronto ,Toronto, Ontario, Canada</p>
          <p>- Shepard Fairey : Printed Matters: Paix Et Justice ,S16 Gallery, Westmount ,Westmount, Quebec, Canada</p>
          <br />
          <div className={More} id="more">
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Shepard fairey: Future Mosaic, Opera Gallery, Dubai, United Arab Emirates, march 15 - april 15</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Shepard fairey: Facing the Giant. 3 decades of dissent & new works, Over The Influence, Los Angeles, California, USA, nov 10,2019 - dec 29</p>
          <p>- Shepard fairey, galerie Alexis Pentcheff, Calvi, France, may 31 - nov 03</p>
          <p>- Shepard fairey: Facing the giant. 3 decades of dissent, StolenSpace Gallery Shoreditch, London, UK, oct 04 - nov 01</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Shepard fairey: Force majeure, Moscow Museum of Modern Art, Petrovka Street Moscow, Russia, sep 19 - nov 04</p>
          <p>- Shepard fairey: Salad days, 1989-1999, Cranbrook Art Museum Bloomfield hills, Michigan, USA, jun 16 - oct 07</p>
          <p>- Shepard fairey: Golden future, galerie Ernst Hilger Vienna | Austria, may 25 - jul 31</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Shepard fairey: Damaged, library street collective, Los Angeles, California, USA, nov 11 - dec 17</p>
          <p>- American civics, Subliminal Projects gallery, Los Angeles, California, USA, jan 20 - feb 10</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Shepard fairey at the mcnay, Mcnay art museum San Antonio, Texas, USA, apr 27 - sep 11</p>
          <p>- Banned in babylon: The art and culture of bad brains, Subliminal projects gallery Los Angeles, California, USA, jul 23 - aug 20</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Shepard fairey: Your Eyes Here, Cac Málaga malaga | Spain, jun 26 - sep 27</p>
          <p>- Shepard fairey: Superman is dead, Danysz Gallery, Paris 11e, Paris, France, jul 04 - aug 01</p>
          <p>- Shepard Fairey: Harmony & Discord, Pace Prints, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Shepard fairey: 50 shades of black, Subliminal projects gallery, Los Angeles, California, USA, apr 16,2014 - may 17,2014</p>
          <p>- Power & Glory, Halsey Institute of Contemporary Art, Charleston, SC, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- 10 year Anniversary show, White walls San Francisco, California, USA, mar 09,2013 - apr 06,2013</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- 'Sound & vision' by Shepard Fairey, Stolen Space Gallery Shoreditch, London, UK, oct 20,2012 - nov 04,2012</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Shepard Fairey: Your ad here, V1 Gallery Copenhagen, Denmark, aug 06,2011 - sep 03,2011</p>
          <p>- Shepard Fairey: The print show, Danysz Gallery, paris 11e, Paris, France, may 07,2011 - jun 18,2011</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Shepard Fairey: Printed Matters, Subliminal projects gallery, Los Angeles, California, USA, sep 16,2010 - oct 09,2010</p>
          <p>- Shepard Fairey: Supply and demand, Contemporary Arts Center, Cincinnati, Ohio, USA, feb 20,2010 - aug 22,2010</p>
          <p>- May day: shepard fairey, jeffrey deitch, new york (wooster str) soho | new york | usa, may 01,2010 - may 29,2010</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Supply and demand, institute of contemporary art (ica) boston back bay, boston, massachusetts | usa, feb 06 - aug 16</p>
          <p>- April school vacation week activities, institute of contemporary art (ica) boston back bay, boston, massachusetts, usa, apr 21 - apr 24</p>
          <p>- Look for us at the grammys, the shooting gallery san francisco, california, usa, feb 02 - feb 08</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Shepard fairey, institute of contemporary art (ica) boston back bay, boston, massachusetts, usa, jul 30,2008 - oct 26,2008</p>
          <p>- Shepard fairey, kp projects park la brea - los angeles, usa, dec 01,2007 - jan 14,2008</p>
          <p>- White Walls Gallery, San Francisco, CA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- E Pluribus Venom, Jonathan LeVine Gallery, New York, NY, USA</p>
          <p>- Nineteen Eighty Four Pa, StolenSpace Gallery, London, England</p>
          <p>- Merry Karnowsky Gallery, Los Angeles, CA, USA</p>
          <p>- Shepard Fairey ,KP Projects ,Park La Brea, Los Angeles, California, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Wax Poetics Gallery, Burbank, CA, USA</p>
          <p>- White Walls Gallery, San Francisco, CA , USA</p>
          <p>- Maxalot Gallery, Barcelona, Spain</p>
          <p>- Galerie Magda Danysz, Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- EXIT Gallery, Hong Kong, China</p>
          <p>- DC Gallery, Denver, CO, USA</p>
          <p>- Hard Rock Café Gallery, Orlando, FL, USA</p>
          <p>- Toyroom Gallery, Sacramento, CA, USA</p>
          <p>- OX-OP Gallery, Minneapolis, MN, USA</p>
          <p>- Voice 1156 Gallery, San Diego, CA</p>
          <p>- Merry Karnowsky Gallery, Los Angeles, CA, USA</p>
          <p>- Black Floor Gallery, Philadelphia, PA, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Kerckhof Gallery, UCLA, Los Angeles, CA, USA</p>
          <p>- The Ad Shop, Brussels, Belgium</p>
          <p>- Toy Room Gallery, Sacramento, CA, USA</p>
          <p>- V1 Gallery, Copenhagen, Denmark</p>
          <p>- Kantor Gallery Window, New York, NY, USA</p>
          <p>- Shooting Gallery, San Francisco, CA, USA</p>
          <p>- Gallery Katz, Boston, MA, USA</p>
          <p>- Martin Mcintosh Outer Gallery, Melbourne, Australia</p>
          <p>- Merry Karnowsky Gallery, Los Angeles, CA, USA</p>
          <p>- Paragraph Gallery, Kansas City, MO, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- La Base, Paris, France</p>
          <p>- Perihelion Arts, Phoenix, AZ, USA</p>
          <p>- SIXSPACE, Los Angeles, CA, USA</p>
          <p>- CPOP, Detroit, MI, USA</p>
          <p>- Think Tank, CO, USA</p>
          <p>- RED FIVE, San Francisco, CA, USA</p>
          <p>- Kantor Gallery Window, New York, NY, USA</p>
          <p>- Milk, San Francisco, CA, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Emporium Gallery, Encinitas, CA, USA</p>
          <p>- 1300 Gallery, Cleveland, OH, USA</p>
          <p>- Boston Museum, Boston, MA, USA</p>
          <p>- Dept/Fumiya Gallery, Tokyo, Japan</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Tin Man Alley Gallery, New Hope, PA, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Capsule, Birmingham, England</p>
          <br />
          <p  style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- New York Street Style : création des années 80, Ghost Galerie, Paris, juillet - septembre 2022.</p>
          <p>- Shepard Fairey, Galerie Itinerrance / What’s Art Gallery / Art Kind / Nevermind Art Gallery, Paris, District 13 International Art Fair Drouot, 15-18 Septembre 2022.</p>
          <p>- Accrochage saisonnier, Ghost Galerie, Paris, France, Janvier-Juin 2022</p>
          <p>- Awakened Voices: A Curated Look From Around The World, Over the Influence</p>
          <p>- Art With Text: The Message is the Medium, Alpha 137 Gallery</p>
          <p>- Unique Paintings and Works on Paper and Board, Alpha 137 Gallery</p>
          <p>- Street Art & Urban Art, Alpha 137 Gallery</p>
          <p>- Inside the Pictures, End to End Gallery</p>
          <p>- Seeing Red, Alpha 137 Gallery</p>
          <p>- 25th Anniversary Exhibition, KP Projects</p>
          <p>- Keith Haring to Banksy: The Skateboard Sale, EHC Fine Art</p>
          <p>- Blue Chip Artists, Patrick Jones Gallery</p>
          <p>- Shepard Fairey / OBEY & Friends, GALERIE S CASSIS</p>
          <p>- Select Prints and Multiples, Alpha 137 Gallery</p>
          <p>- New York Street Style : création des années 80, GHOST GALERIE</p>
          <p>- Prints Matter x2, Artscoops</p>
          <p>- Street Art - Vignettes, Leviton Fine Art</p>
          <p>- #INYAFACE 4, Addicted Art Gallery</p>
          <p>- Artists Skates, Plates & Design Objects, Alpha 137 Gallery</p>
          <p>- Art for Social Change: Political Prints & Multiples from the 1960s to today, Alpha 137 Gallery</p>
          <p>- Pax Brittanica, Alpha 137 Gallery</p>
          <p>- Graffiti and Urban Art II, Rodríguez</p>
          <p>- June Group Show, Galerie Christiane Vallé</p>
          <p>- New Works by Monica Melgar & Sarah Larsen, Patrick Jones Gallery</p>
          <p>- Digital and Physical Graffiti, Waltham Fine Art</p>
          <p>- Ode to Street Art, Gallery Auximenes</p>
          <p>- "P&M" - Prints & Multiples, GALERIE S CASSIS</p>
          <p>- Urban & Street Art Exhibition, Bakerhouse Gallery</p>
          <p>- Rodriguez - Graffiti and Street Art, Rodríguez</p>
          <p>- Chromatic, Patrick Jones Gallery</p>
          <p>- Exclusive print sale from various artists by 2B Art Gallery</p>
          <p>- Artists Inspired by Music: Interscope Reimagined, Interscope</p>
          <p>- WINTER SELECTION, EHC Fine Art</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- I only have to land it once, black book gallery englewood, colorado, usa, nov 13 - dec 11</p>
          <p>- Ciao maschio, galleria comunale d`arte moderna roma rome | italia, jun 23 - nov 14</p>
          <p>- Cld’s cool, new’s cool, art share l.a.downtown los angeles, los angeles, california, usa, sep 11 - oct 10</p>
          <p>- Unity, stolen space gallery shoreditch, london, uk, sep 10 - oct 03</p>
          <p>- Pow! wow! the first decade: from hawai’i to the world, thinkspace projectsculver city, los angeles, california, usa, may 15 - sep 19</p>
          <p>- Remix: the art of music, gabba gallery los angeles, california, usa, aug 21 - sep 18</p>
          <p>- Prints published during the pandemic, bert green fine art chicago | illinois | usa, jul 10 - sep 10</p>
          <p>- Beyond the streets on paper, southampton arts center southampton, new york, usa, jul 17 - aug 28</p>
          <p>- Together again, baf vancouver, british columbia, canada, jul 15 - aug 24</p>
          <p>- ¡Printing the revolution! the rise and impact of chicano graphics, 1965 to now, smithsonian american art museum downtown, washington d.c., district of columbia, usa, may 14 - aug 08</p>
          <p>- Online: artists band together, richard f. brush art gallery canton | new york | usa, jan 25 - jun 30</p>
          <p>- Salon 02, louis buhl & co. detroit, michigan, usa, feb 27 - apr 27 </p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- The terrordome, black book gallery englewood, colorado, usa, sep 05 - oct 03</p>
          <p>- We used to gather, library street collective, detroit detroit, michigan, usa,jul 18 - sep 18</p>
          <p>- Treasures, danysz gallery, paris 11e, paris, france, may 30 - jun 27</p>
          <p>- Sidewalk activism, oceanside museum of art oceanside, california, usa, feb 15 - jun 21</p>
          <p>- Here and now: a survey on new contemporary art, fort wayne museum of art ft. wayne, indiana, usa, mar 14 - may 17</p>
          <p>- Lasting impressions, della sposa london, uk, mar 05 - apr 10</p>
          <p>- Group show, matthew namour montreal, quebec, canada, nov 19 - jan 01</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- New contemporary: shepard fairey & vasilena gankovska, hilger next vienna, austria sep 07,2019 - nov 02,2019</p>
          <p>- Look both ways: the illicit liaison between image and information, school of visual arts, chelsea gallery chelsea, new york, usa, aug 24,2019 - sep 21,2019</p>
          <p>- Donald trump: poster boy, Haifa museum of art haifa, Israel, mar 30,2019 - sep 01,2019</p>
          <p>- The public trust. 15 years, the public trust dallas main street district, dallas, texas, usa, jun 29,2019 - jul 31,2019</p>
          <p>- Eclectic dreams: art exhibition, westbank gallery notting hill, london, uk, un 07,2019 - jun 20,2019</p>
          <p>- 20 sonoma collectors: celebrating svma’s 20th year, Sonoma Valley museum of art sonoma, California, USA, sep 29,2018 - jan 06,2019</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Paper trail, allouche gallery, New York, USA, sep 06,2018 - oct 07,2018</p>
          <p>- Visual language, faction art projects harlem, new york, usa, sep 14,2018 - oct 06,2018</p>
          <p>- Now & then. beautiful losers, alleged gallery & the 90’s lower east side, the hole, 312 st. soho, new york, usa, aug 24,2018 - sep 01,2018</p>
          <p>- Remix: the art of music, gabba gallery los angeles, california, usa, aug 04,2018 - aug 25,2018</p>
          <p>- Wish you were here: featuring work from 40 leading artists, Stolen Space gallery Shoreditch, London, UK, jul 06,2018 - aug 05,2018</p>
          <p>- Selections, Pace prints, 26th street chelsea, New York, USA, jun 21,2018 - aug 03,2018</p>
          <p>- One of a kind, galería 3 punts barcelona, Spain, mar 21,2018 - jun 30,2018</p>
          <p>- Artists take action! recent acquisitions from the davis, davis museum and cultural center, wellesley college wellesley, massachusetts, usa, feb 13,2018 - jun 10,2018</p>
          <p>- Beyond the familiar, outré gallery, melbourne, australia, may 11,2018 - jun 04,2018</p>
          <p>- Art from the streets, artscience museum singapore, Singapore, jan 13,2018 - jun 03,2018</p>
          <p>- Marking a movement: selections from hear our voice, Asheville art museum asheville, North Carolina, USA, jan 08,2018 - mar 13,2018</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- On aime l'art...!! un choix d'éric mézil parmi les oeuvres de la collection agnès b. collection lambert en avignon avignon, france, jul 06,2017 - nov 05,2017</p>
          <p>- Summer group show, jacob lewis gallery chelsea, new york, usa, jul 13,2017 - aug 18,2017</p>
          <p>- Selections, pace prints, 26th street chelsea, new york, usa, may 31,2017 - aug 18,2017</p>
          <p>- Multiples of multiples, jonathan levine projects jersey city, new jersey, usa, jun 24,2017 - jul 22,2017</p>
          <p>- Hear our voice, asheville art museum asheville, north carolina, usa, may 20,2017 - jul 16,2017</p>
          <p>- Juxtapozed, fort wayne museum of art ft. wayne, indiana, usa, apr 22,2017 - jul 09,2017</p>
          <p>- Twenty-one: celebrating 21 years of subliminal, subliminal projects gallery los angeles, california, usa, jun 03,2017 - jun 15,2017</p>
          <p>- Welcome to new jersey, jonathan levine projects jersey city, new jersey, usa, feb 18,2017 - mar 18,2017</p>
          <p>- Influential, over the influence, hong kong, dec 16,2016 - mar 04,2017</p>
          <p>- The hilger collection. part 2: painted surfaces, hilger brot kunsthalle vienna, austria, sep 17,2016 - jan 21,2017</p>
          <p>- Art, page bond gallery richmond, virginia, usa, dec 08,2016 - jan 14,2017</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Pressed for time: history of printmaking, flint institute of arts flint, michigan, usa, sep 10,2016 - dec 30,2016</p>
          <p>- One collector's dream, polk museum of art lakeland, florida, usa, sep 24,2016 - dec 03,2016</p>
          <p>- Flawless, galería 3 punts barcelona, spain, jun 16,2016 - sep 23,2016</p>
          <p>- Shepard fairey at the mcnay, mcnay art museum san antonio, texas, usa, apr 27,2016 - sep 11,2016</p>
          <p>- Exhibitionism: the rolling stones, saatchi gallery london, uk, apr 05,2016 - sep 04,2016</p>
          <p>- Recent editions, pace prints, 57th street, new york, usa, jul 28,2016 - aug 26,2016</p>
          <p>- Summer selections, pace prints, 26th street chelsea, new york, usa, jun 16,2016 - aug 19,2016</p>
          <p>- New editions, pace prints, 26th street chelsea, new york, usa, jun 16,2016 - jul 15,2016</p>
          <p>- Venturing beyond: graffiti and the everyday utopias of the street, somerset house london, uk, mar 03,2016 - may 02,2016</p>
          <p>- Outsidein, alyce de roulet williamson gallery, art center college of design pasadena, california, usa, oct 09,2015 - jan 10,2016</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- A very anxious feeling : artwork from the collection of melva bucksbaum and raymond learsy, the mattatuck museum arts & history cente rwaterbury, connecticut, usa, sep 20 - nov 29</p>
          <p>- New editions, pace prints, 57th street, new york, usa, oct 15 - nov 28</p>
          <p>- Interventions, danysz gallery, paris 11e, paris, france, oct 24 - nov 28</p>
          <p>- Rock style. curated by tommy hilfiger and jeffrey deitch, sotheby's london mayfair, london, uk, sep 22 - oct 30</p>
          <p>- Brotherhood, jonathan levine projects chelsea, new york, usa, sep 12 - oct 10</p>
          <p>- Earth Crisis, Eiffel Tower, Paris, France</p>
          <p>- On Our Hands, Jacob Lewis Gallery, New York, NY, USA</p>
          <p>- Printed Matters & Public Matter Show: Detroit, Library Street Collective, Detroit, Michigan, USA</p>
          <p>- New editions, pace prints, 26th street chelsea, new york, usa, may 01 - jul 02</p>
          <p>- Outside in: art of the street, middlebury college museum of art middlebury, vermont, usa, feb 13 - apr 19</p>
          <p>- Agents provocateurs, subliminal projects gallery los angeles, california, usa, jan 24 - feb 21</p>
          <p>- Mapping the city, somerset house london, uk, jan 22 - feb 15,2015 </p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Cruel summer, jonathan levine projects chelsea, new york, usa, aug 06,2014 - aug 23,2014</p>
          <p>- Dennis morris and shepard fairey: s.i.d (superman is dead), subliminal projects gallery los angeles, california, usa, dec 13,2013 - jan 11,2014</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Outside ii: group exhibition, 34fineart cape town, south africa, sep 17,2013 - nov 09,2013</p>
          <p>- Recent editions, pace prints, new york, usa, jun 06,2013 - aug 23,2013</p>
          <p>- Got the message? 50 years of political posters, art gallery of ballarat ballarat, australia, mar 02,2013 - apr 14,2013</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Blend: group exhibition, 34fineart cape town, south africa, dec 11 - jan 26,2013</p>
          <p>- Recent editions, pace prints, 57th street 57th street, new york, usa, jul 16, - aug 17,</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Outside, 34fineart cape town, south africa, oct 25,2011 - nov 19,2011</p>
          <p>- Nose job, eric firestone gallery, east hamptoneast hampton, new york, usa, jul 15,2011 - aug 21,2011</p>
          <p>- Too fast to live, too young to die, subliminal projects gallery los angeles, california, usan feb 25,2011 - mar 26,2011</p>
          <p>- The big picture, subliminal projects gallery, Los Angeles, california, usa, jan 22,2011 - feb 19,2011</p>
          <p>- Street art, the herbert art gallery & museum coventry, uk, oct 09,2010 - jan 16,2010</p>
          <p>- Viva la revolucion: a dialogue with the urban landscape, mcasd, museum of contemporary art san diego, downtown san diego, california, usa, jul 18,2010 - jan 02,2010</p>
          <p>- Winter group show, white walls san francisco, california, usa, dec 11,2010 - jan 01,2011</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Primary flight, miami, florida, usa, dec 01,2010 - dec 05,2010</p>
          <p>- Le salon de cercle de la culture, Circle culture art gmbh, berlin, germany, jun 25,2010 - jul 23,2010</p>
          <p>- From style writing to art: a street art anthology , Danysz Gallery, Paris 11e, france, dec 23,2009 - jan 21,2010</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Shepard fairey, banksy, david choe, kaws, nick walker, and more: get rich quick, carmichael gallery of contemporary art west hollywood, los angeles, california, usa, may 07,2009 - may 07,2009</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Regime change starts at home shepard fairey, al farrow, and paul d. miller (dj spooky) irvine contemporary art downtown, washington d.c. | district of columbia, usa, oct 18,2008 - dec 06,2008</p>
          <p>- Shepard fairey, institute of contemporary art (ica) boston, back bay, boston, massachusetts, usa, jul 30,2008 - oct 26,2008</p>
          <p>- Obey + d-face, danysz gallery, paris, france, oct 11,2008 - oct 25,2008 2006</p>
          <p>- OXOP Gallery, Minneapolis, MN, USA</p>
          <p>- New Art Center, Newtonville, MA, USA</p>
          <p>- Galerie de Jour Agnès B, Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Galerie Magda Danysz, Paris, France</p>
          <p>- Dyezu Experiment, Tokyo, Japan</p>
          <p>- Mendenhall Sobieski Gallery, Pasadena, CA, USA</p>
          <p>- DC Gallery, Denver, CO, USA</p>
          <p>- V1 Gallery, Copenhagen, Denmark</p>
          <p>- Project Gallery, Los Angeles, CA, USA</p>
          <p>- Track 16 Gallery, Los Angeles, CA, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Paul Rodgers 9W Gallery, New York, NY, USA</p>
          <p>- Beautiful Losers, CAC, Cincinnati, OH, USA</p>
          <p>- Ducky Waddle's Emporium, Encinitas, CA, USA</p>
          <p>- Streetwise 3, Santa Monica, CA, USA</p>
          <p>- Beautiful Losers, Yerba Buena, San Francisco, CA, USA</p>
          <p>- QEEROPHONIC, Subliminal Projects, Los Angeles, CA, USA</p>
          <p>- Carlos Irizarry Gallery, San Juan, Puerto Rico</p>
          <p>- OBJET Art Space, Miami, FL, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Session the Bowl, Deitch Projects, New York, NY, USA</p>
          <p>- Quite Riot, Long Beach, CA, USA</p>
          <p>- Design Is Kinky Conference, Sydney, Australia</p>
          <p>- Back Jumps Exhibition, Berlin, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Max Fish, New York, NY, USA</p>
          <p>- Merry Karnowski Gallery, Hollywood, CA, USA</p>
          <p>- Day by Day Gallery, Richmond, VA, USA</p>
          <p>- The Blue Cube, Columbus, OH, USA</p>
          <p>- Seven Degrees Gallery, Laguna Beach, CA, USA</p>
          <p>- Apart Gallery, London, UK</p>
          <p>- Modart, CA, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- CPOP Gallery, Detroit, MI, USA</p>
          <p>- Levi's Vortex Gallery</p>
          <p>- Bush Gallery, Providence, RI</p>
          <p id="end">- MOCA DC, Washington, DC, USA</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default ShepardFairey;