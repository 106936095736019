// extracted by mini-css-extract-plugin
export var ArtistDescription = "ShepardFairey-module--ArtistDescription--DFoxj";
export var ArtistInfos = "ShepardFairey-module--ArtistInfos--xOP-w";
export var ButtonWrapper = "ShepardFairey-module--ButtonWrapper --43hYj";
export var CardWrapper = "ShepardFairey-module--CardWrapper--gouNS";
export var CarrouselWrapper2 = "ShepardFairey-module--CarrouselWrapper2--uHXh9";
export var Citations = "ShepardFairey-module--Citations--KmOB9";
export var DescriptionWrapper = "ShepardFairey-module--DescriptionWrapper--OFiSq";
export var ImageWrapper = "ShepardFairey-module--ImageWrapper--2vb9s";
export var LinkWrapper = "ShepardFairey-module--LinkWrapper--B5ODc";
export var MobileProtrait = "ShepardFairey-module--MobileProtrait--3xZjP";
export var More = "ShepardFairey-module--More--0RLmY";
export var MoreButton = "ShepardFairey-module--MoreButton--I+fMi";
export var NameWrapper = "ShepardFairey-module--NameWrapper--mGneb";
export var PdpWrapper = "ShepardFairey-module--PdpWrapper--nWMku";
export var PhotosWrapper = "ShepardFairey-module--PhotosWrapper--zLnLE";
export var ProfilWrapper = "ShepardFairey-module--ProfilWrapper--NmlC+";
export var TitleWrapper = "ShepardFairey-module--TitleWrapper--3EoEZ";
export var Wrapper = "ShepardFairey-module--Wrapper--MEUYj";